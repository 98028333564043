import { useFeeDiscountList } from "@gocardless/api/dashboard/fee-discount";
import { DiscountType } from "@gocardless/api/dashboard/types";

import { ToTranslate } from "../i18n";

export interface RewardListItem {
  id: DiscountType;
  title: JSX.Element;
  description: JSX.Element;
  isUnlocked: boolean;
}

const rewardsListConfig: Omit<RewardListItem, "isUnlocked">[] = [
  {
    id: DiscountType.The30DaysFreeFeeActivationCreateAccount,
    title: <ToTranslate>Create an account</ToTranslate>,
    description: <ToTranslate>30 days without fees!</ToTranslate>,
  },
  {
    id: DiscountType.The15DaysFreeFeeActivationCompleteSetup,
    title: <ToTranslate>Tell us your payment pains/goals</ToTranslate>,
    description: <ToTranslate>Unlock +15 extra days</ToTranslate>,
  },
  {
    id: DiscountType.The15DaysFreeFeeActivationCompleteSetupExtension,
    title: <ToTranslate>Fill in your business details</ToTranslate>,
    description: <ToTranslate>Unlock +15 extra days</ToTranslate>,
  },
  {
    id: DiscountType.The30DaysFreeFeeActivationAddFirstCustomer,
    title: <ToTranslate>Get your first customer</ToTranslate>,
    description: <ToTranslate>Unlock +30 extra days</ToTranslate>,
  },
  {
    id: DiscountType.The30DaysFreeFeeActivationCollectFirstPayment,
    title: <ToTranslate>Collect your first payment</ToTranslate>,
    description: <ToTranslate>Unlock +30 extra days</ToTranslate>,
  },
];

export const useRewardsList = () => {
  const { data: discountData } = useFeeDiscountList();

  const isUnlocked = (discountType: DiscountType) => {
    const discount = discountData?.fee_discounts?.some(
      (d) => d?.discount_type === discountType
    );
    return Boolean(discount);
  };

  const rewardsList: RewardListItem[] = rewardsListConfig.map((reward) => ({
    ...reward,
    isUnlocked: isUnlocked(reward.id),
  }));

  return {
    rewardsList,
    isUnlocked,
  };
};
