import { DayOfWeek } from "@gocardless/api/dashboard/types";
import { CalendarDate } from "@gocardless/flux-react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useI18n, UserLocale } from "src/technical-integrations/i18n";

export interface ConvertToLocaleDateString {
  date: Date | string | undefined | null;
  options?: Intl.DateTimeFormatOptions;
  locale?: UserLocale;
  shouldLocalise?: boolean;
}

export const convertToLocaleDateString = ({
  date,
  options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  },
  locale = UserLocale.EN_GB,
  shouldLocalise = false,
}: ConvertToLocaleDateString) => {
  const validDate = date ?? Date.now();
  const dateToShow = shouldLocalise ? validDate : offsetUserDate(validDate);

  return new Date(dateToShow).toLocaleDateString(locale, options);
};

export const dayOfWeekAsString = (day: DayOfWeek): string => {
  switch (day) {
    case DayOfWeek.Monday:
      return i18n._(
        t({
          id: "Monday",
          message: "Monday",
        })
      );
    case DayOfWeek.Tuesday:
      return i18n._(
        t({
          id: "Tuesday",
          message: "Tuesday",
        })
      );
    case DayOfWeek.Wednesday:
      return i18n._(
        t({
          id: "Wednesday",
          message: "Wednesday",
        })
      );
    case DayOfWeek.Thursday:
      return i18n._(
        t({
          id: "Thursday",
          message: "Thursday",
        })
      );
    case DayOfWeek.Friday:
      return i18n._(
        t({
          id: "Friday",
          message: "Friday",
        })
      );
    case DayOfWeek.Saturday:
      return i18n._(
        t({
          id: "Saturday",
          message: "Saturday",
        })
      );
    case DayOfWeek.Sunday:
      return i18n._(
        t({
          id: "Sunday",
          message: "Sunday",
        })
      );
  }
};

export const useConvertToLocaleDateString = (
  args: ConvertToLocaleDateString
) => {
  const [locale] = useI18n();
  return convertToLocaleDateString({ locale, ...args });
};

export const fromCalendarDate = (d: CalendarDate): Date =>
  new Date(d.year, d.month - 1, d.day);

export const toCalendarDate = (d: CalendarDate): Date =>
  new Date(d.year, d.month - 1, d.day, 23, 59, 59, 999);

export const offsetUserDate = (date: Date | string | number) => {
  const dateObject = new Date(date);
  const timezoneOffset = dateObject.getTimezoneOffset() * 60000;
  return new Date(dateObject.getTime() + timezoneOffset);
};
