// eslint-disable-next-line no-restricted-imports
import { OptimizelyDecideOption, useDecision } from "@optimizely/react-sdk";

import { OptimizelyFlag, OptimizelyVariant } from "./constants";

interface OptimizelyVariationHookConfig {
  flag: OptimizelyFlag;
  options?: { decideOptions?: OptimizelyDecideOption[] };
  variationKey?: OptimizelyVariant;
}

/**
 *   centralize experiments and features, using the `useDecision` hook
 * */
export const useOptimizelyVariation = ({
  flag,
  options,
  variationKey = OptimizelyVariant.FLAG_ON,
}: OptimizelyVariationHookConfig) => {
  const [decision, clientReady, didTimeout] = useDecision(
    flag,
    options?.decideOptions ? { decideOptions: options.decideOptions } : {}
  );

  return {
    isFlagEnabled: decision.enabled,
    isVariationOn: decision.enabled && variationKey === decision.variationKey,
    clientReady,
    didTimeout,
  };
};
