import { useFeeDiscountList } from "@gocardless/api/dashboard/fee-discount";
import {
  DiscountType,
  FeeDiscountResource,
} from "@gocardless/api/dashboard/types";
import { useCallback, useEffect, useState } from "react";

interface UseActivationDiscount {
  activationDiscount?: FeeDiscountResource;
  firstPayoutDiscount?: FeeDiscountResource;
  remainingDays?: number;
  digits?: string[];
  discountLength?: number;
  loaded: boolean;
}

export const DISCOUNT_EXTENSION_LENGTH = 30;

// The order of the array matters
const discountTypes = [
  DiscountType.CustomerSupport30_DaysDiscount,
  DiscountType.PreActiveDiscount90_Days,
  DiscountType.CustomisableActivationDiscount,
  DiscountType.ActivationDiscount90_Days,
  DiscountType.The10DaysFreeFeeActivationUploadDocument,
  DiscountType.The15DaysFreeFeeActivationCompleteSetup,
  DiscountType.The15DaysFreeFeeActivationCompleteSetupExtension,
  DiscountType.The30DaysFreeFeeActivationAddFirstCustomer,
  DiscountType.The30DaysFreeFeeActivationCollectFirstPayment,
  DiscountType.The30DaysFreeFeeActivationCompleteSetup,
  DiscountType.The30DaysFreeFeeActivationCreateAccount,
];

/*
 * This returns the number of free transaction days
 * remaining for the organisation with one of the recognised
 * activation discounts
 */
export const useActivationDiscount = (): UseActivationDiscount => {
  const { data: discountData, isValidating } = useFeeDiscountList();

  const [activationDiscount, setActivationDiscount] = useState<
    FeeDiscountResource | undefined
  >();
  const findDiscount = useCallback(
    (discountType: DiscountType) =>
      discountData?.fee_discounts?.find(
        (discount) => discount.discount_type === discountType
      ),
    [discountData?.fee_discounts]
  );

  useEffect(() => {
    let latestEndTimestamp = 0;

    for (const discountType of discountTypes) {
      const discount = findDiscount(discountType);

      if (
        discount &&
        discount.end_date &&
        Date.parse(discount.end_date) > latestEndTimestamp
      ) {
        latestEndTimestamp = Date.parse(discount.end_date);
        setActivationDiscount(discount);
      }
    }
  }, [findDiscount]);

  const firstPayoutDiscount = findDiscount(
    DiscountType.FirstPayoutDiscount30_DaysTgExp
  );

  const loaded = discountData?.fee_discounts !== undefined && !isValidating;

  if (
    !activationDiscount ||
    !activationDiscount.start_date ||
    !activationDiscount.end_date
  ) {
    return { activationDiscount, loaded };
  }

  const activationStartDate = Date.parse(activationDiscount.start_date);
  const activationEndDate = Date.parse(activationDiscount.end_date);

  const endDate = Math.max(
    firstPayoutDiscount?.end_date
      ? Date.parse(firstPayoutDiscount?.end_date)
      : 0,
    activationEndDate
  );

  if (Date.now() > endDate) {
    return { activationDiscount, firstPayoutDiscount, loaded };
  }

  const remainingDays = Math.ceil((endDate - Date.now()) / 86400000); // ms in one day
  const digits = remainingDays.toString().padStart(2, "0").split("");

  const discountLength = Math.ceil(
    (activationEndDate - activationStartDate) / 86400000
  ); // ms in one day

  return {
    activationDiscount,
    firstPayoutDiscount,
    remainingDays,
    digits,
    discountLength,
    loaded,
  };
};
