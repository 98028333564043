import {
  useConvertToLocaleDateString,
  ConvertToLocaleDateString,
} from "../../common/date-time";

const LocaleDateString: React.FC<ConvertToLocaleDateString> = (props) => {
  const date = useConvertToLocaleDateString(props);

  return <>{date}</>;
};

export default LocaleDateString;
